//eslint-disable-next-line
import React, { useEffect, useState } from 'react';

import MainLayout from '../../components/MainLayout';
import HeaderInside from '../../components/HeaderInside';
import { NotificationItem, useNotifications } from '../../contexts/notifications';

import noNotifications from '../../img/no-notifications.svg';
import notificationAlert from '../../img/notification-alert.svg';
import informationIcon from '../../img/information-icon.svg';
import notificationsAlertDisabled from '../../img/notifications-alert-disabled.svg';
import checkedActive from '../../img/checked-active.svg';
import trashIcon from '../../img/trash.svg';
import checkedDisabled from '../../img/checked-disabled.svg';
import moment from 'moment';
import 'moment/locale/pt-br';
import ModalNotifications from './components/ModalNotifications';
import Skeleton from 'react-loading-skeleton';
import config from '../../config';

import {
  ButtonDelete,
  ButtonDeleteArea,
  ButtonLoadingMoreNotifications,
  Container,
  ImageNotification,
  NotificationArea,
  NotificationAreaItem,
  NotificationDescriptionArea,
  NotificationFooter,
  Panel,
  PanelNoNotification,
  SkeletonLoadingArea,
} from './styles';
import { ModalInformation } from 'pages/course/components/ModalInformation';

moment.locale('pt-br');

export default function Notifications() {
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState<NotificationItem | null>(null);
  const [sortedUnreadFirst, setSortedUnreadFirst] = useState<NotificationItem[] | null>(null);
  const heigthPanel = document.documentElement.clientHeight - 242;
  const [modalInformation, setModalInformation] = useState({
    open: false,
    icon: <></>,
    title: '',
    primaryButtonAction: () => {},
    primaryButtonText: '',
    secondaryButtonAction: () => {},
    secondaryButtonText: '',
    onclose: () => {},
    iconPosition: 'left',
  });
  const {
    notifications: notificationsApi,
    handleReadNotification,
    isLoading: notificationsLoading,
    getNotifications,
    finishedNotificationsList,
    handleDeleteNotification,
  } = useNotifications();

  const hasNotifications = notificationsApi.length > 0;
  const unreadNotifications = notificationsApi?.filter(
    (notification) => !notification.read
  )?.length;
  const verifyIfAllNotificationsAreRead = notificationsApi.every(
    (notification) => notification.read
  );
  const showDeleteAllButton = hasNotifications && verifyIfAllNotificationsAreRead;

  useEffect(() => {
    if (notificationsApi) {
      const sorted = notificationsApi.sort((a, b) => {
        if (a.read && !b.read) return 1;
        if (!a.read && b.read) return -1;
        return 0;
      });
      setSortedUnreadFirst(sorted);
    }
  }, [notificationsApi]);

  const handleOpenNotification = (notification: NotificationItem) => {
    setNotificationModal(notification);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setNotificationModal(null);
    setOpenModal(false);
  };

  const handleCheckAsRead = async (id: string) => {
    await handleReadNotification(id);
  };

  const returnDate = (date?: string) => {
    if (date) {
      return moment(new Date(date)).format('DD MMM YYYY [às] HH[:]mm');
    }
  };

  const handleGetMoreNotifications = () => {
    setPage(page + 1);
    getNotifications(page + 1);
  };

  const handleDeleteAllScreenNotifications = () => {
    setModalInformation({
      open: true,
      icon: <img src={informationIcon} alt="Alerta de apagar todas" />,
      title: 'Tem certeza que deseja remover todas as notificações?',
      primaryButtonAction: async () => {
        const ids = notificationsApi?.map((notification) => notification.id);
        if (ids) {
          handleDeleteNotification(ids);
          handleCloseModalInformation();
        }
      },
      primaryButtonText: 'Sim, deletar tudo',
      secondaryButtonAction: () => handleCloseModalInformation(),
      secondaryButtonText: 'Não, quero manter',
      onclose: () => handleCloseModalInformation(),
      iconPosition: 'left',
    });
  };

  const handleDeleteOneNotification = (id: string) => {
    setModalInformation({
      open: true,
      icon: <img src={informationIcon} alt="Alerta de apagar" />,
      title: 'Tem certeza que deseja remover esta notificação?',
      primaryButtonAction: async () => {
        handleDeleteNotification([id]);
        handleCloseModalInformation();
      },
      primaryButtonText: 'Sim, remover',
      secondaryButtonAction: () => handleCloseModalInformation(),
      secondaryButtonText: 'Não, quero manter',
      onclose: () => handleCloseModalInformation(),
      iconPosition: 'left',
    });
  };

  const handleCloseModalInformation = () => {
    setModalInformation({
      open: false,
      icon: <></>,
      title: '',
      primaryButtonAction: () => {},
      primaryButtonText: '',
      secondaryButtonAction: () => {},
      secondaryButtonText: '',
      onclose: () => {},
      iconPosition: 'left',
    });
  };

  const renderNotifications = () => {
    if (notificationsLoading && !hasNotifications) {
      return (
        <SkeletonLoadingArea>
          <Skeleton height={120} count={5} />
        </SkeletonLoadingArea>
      );
    }

    if (!hasNotifications) {
      return (
        <PanelNoNotification>
          <img src={noNotifications} alt="Sem notificações" />
          <h3>Você não tem nenhuma notificação!</h3>
        </PanelNoNotification>
      );
    }

    return (
      <NotificationArea>
        {sortedUnreadFirst?.map((notification) => (
          <NotificationAreaItem
            readed={notification.read}
            key={notification.id}
            onClick={() => handleOpenNotification(notification)}
          >
            <NotificationDescriptionArea>
              {notification?.image?.original ? (
                <ImageNotification image={config.DOWNLOAD_URL + notification.image.original} />
              ) : (
                <img
                  src={notification.read ? notificationsAlertDisabled : notificationAlert}
                  alt="Notificação"
                />
              )}
              <div className="text-title-area">
                <h3>{notification.title}</h3>
                <h4>{notification?.text?.slice(0, 100)}</h4>
              </div>
              <ButtonDelete
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteOneNotification(notification.id);
                }}
              >
                <img src={trashIcon} alt="Deletar" width={20} height={20} />
              </ButtonDelete>
            </NotificationDescriptionArea>
            <NotificationFooter>
              <p>{returnDate(notification.post_date)}</p>
              {notification.read ? (
                <div>
                  <p>Lida</p>
                  <img src={checkedDisabled} alt="Lida" />
                </div>
              ) : (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCheckAsRead(notification.id);
                  }}
                >
                  Marcar como lida
                  <img src={checkedActive} alt="Marcar como lida" />
                </button>
              )}
            </NotificationFooter>
          </NotificationAreaItem>
        ))}
        {!finishedNotificationsList && sortedUnreadFirst && sortedUnreadFirst.length > 3 && (
          <ButtonLoadingMoreNotifications onClick={handleGetMoreNotifications}>
            Carregar mais...
          </ButtonLoadingMoreNotifications>
        )}
      </NotificationArea>
    );
  };

  return (
    <MainLayout className="home">
      <Container>
        <HeaderInside
          title={
            unreadNotifications > 0
              ? `${unreadNotifications} notificações não lidas`
              : 'Notificações'
          }
        />
        <ButtonDeleteArea>
          {showDeleteAllButton && (
            <ButtonDelete onClick={handleDeleteAllScreenNotifications}>
              Apagar todos
              <img src={trashIcon} alt="Deletar" width={20} height={20} />
            </ButtonDelete>
          )}
        </ButtonDeleteArea>
        <Panel height={heigthPanel} hasItems={hasNotifications}>
          {renderNotifications()}
        </Panel>
      </Container>
      <ModalNotifications
        handleCloseModal={handleCloseModal}
        notificationModal={notificationModal}
        openModal={openModal}
        returnDate={returnDate}
      />
      <ModalInformation
        icon={modalInformation.icon}
        iconPosition={modalInformation.iconPosition as 'left' | 'center'}
        onclose={modalInformation.onclose}
        open={modalInformation.open}
        primaryButtonAction={modalInformation.primaryButtonAction}
        primaryButtonText={modalInformation.primaryButtonText}
        secondaryButtonAction={modalInformation.secondaryButtonAction}
        secondaryButtonText={modalInformation.secondaryButtonText}
        title={modalInformation.title}
      />
    </MainLayout>
  );
}
